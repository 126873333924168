
























































































































































































































































import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { Observer } from "mobx-vue";
import { SummonViewModel } from "../viewmodels/summon-viewmodel";

@Observer
@Component({
  components: {
    SummonCard: () => import("@/modules/summon/components/summon-card.vue"),
  },
})
export default class SummonDialog extends Vue {
  @Inject() vm!: SummonViewModel;
  @Prop({ type: Boolean }) premiumTicket!: boolean;

  openNextTicket() {
    this.vm.showOpenTicket(true);
  }

  close() {
    if (!this.vm.isSummonDialogLoading && !this.vm.nftLoading) {
      this.vm.closeSummonDialog();
    }
  }

  openDetail() {
    window.open(
      `https://nftmarket.matrixgpt.ai/detail/${process.env.VUE_APP_NFT_ADDRESS}:${this.vm.summonedNft?.id}`,
      "_blank"
    );
  }
}
